import React from 'react'
import { graphql } from 'gatsby'
import get from '@pw-utils/get'
import { Flex, Heading, Link, Box } from '@pw/ui'

import RichText from '../components/RichText'
import createHeadingId from '../components/RichText/createHeadingId'

import { Layout, Container } from '../components/Layout'
import RowOfPosts from '../components/Posts/Row'
import GridOfPosts from '../components/Posts/Grid'

export const PageTemplate = ({ data, location }) => {
  const page = get(data, 'contentfulPage', {})
  const title = get(page, 'title')
  const body = get(page, 'body.json', {})

  return (
    <Layout headerTitle={title} location={location} seo={page.seo}>
      <Container py="8" px={{ base: 0, md: 4 }} maxW={{ md: '1200px' }}>
        <RichText body={body} />
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      body {
        id
        json
        body
      }
      seo {
        title
        description {
          description
        }
      }
      photo {
        fixed(width: 150, height: 150, quality: 80) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`
